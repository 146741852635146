'use client'
import type { FC } from 'react'
import { LoginPageStd } from 'auth/storefront'

interface LoginPageComponentProps {}

const LoginPageComponent: FC<LoginPageComponentProps> = ({}) => {
  return (
    <LoginPageStd
      config={{
        paths: {
          loginApiRoute: '/api/auth/login',
          sessionApiRoute: '/api/auth/session'
        },
        redirects: {
          onLoggedIn: '/account',
          toNewPassword: '/new-password',
          toSignup: '/signup'
        }
      }}
    />
  )
}

export default LoginPageComponent
